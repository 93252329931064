import React from 'react'
import { UserContext } from "../context/userContext";
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {
    motion,
    useScroll,
    useSpring,
    useTransform,
    MotionValue
  } from "framer-motion";
import "../css/shop.css"
import { useParams } from "react-router-dom";
import { useState, useEffect, useContext} from 'react'
import { medusaClient } from '../utils/medusa_client';
import Product_Card from "../components/product_card";
import { useLocation } from 'react-router-dom';

export default function Shop() {
    const {session, setSessions, supabase, cart, setCart} = useContext(UserContext)
    const [page, setPage] = useState(5)
    const [products, setProducts] = useState();
    // const { products, isLoading } = useProducts()
    // console.log("params", type)
    let paramType = useParams()
    // console.log("PARAMS", paramType)
    let location = useLocation();
    // console.log("loc", location)
    // let type
    // if (location.state && location.state.type ) {
    let type = location.state.type
    let collection = location.state.collection
    // } else
    // {
    //     type = "n"
    // }
    // console.log("type", type)

    const [leng, setLeng] = useState(0)

    // useEffect(() => {

    // }, )
    async function getProducts() {
      
      let prods
      if (type != "-1") {
        prods = await medusaClient.products.list({ type_id: [type.id]})
      } else if (collection != "-1") {
        // console.log("wte", collection)
        prods = await medusaClient.products.list({collection_id: [collection.id]})
        // console.log("wte2", prods)

      }
      const perChunk = 4// items per chunk    

      let resultArray = []
      let counter = 0
      for (let item of prods.products) {
          const chunkIndex = Math.floor(counter/perChunk)
          // console.log("item", item)
          if(!resultArray[chunkIndex]) {
              resultArray[chunkIndex] = [] // start a new chunk
          }

          resultArray[chunkIndex].push(item)
          // setProducts(products => [...products, {"name": item.product_name, "price": item.product_price, "image": item.product_image, "id": item.product_id, 
          // "desc": item.product_desc, "size":item.product_size, "color": item.product_color, "class":item.product_class}])

          counter += 1
      }

      // console.log(prods.products)
      setProducts(prods.products)
      // setProducts(prods)
      return prods.products
    }

    useEffect(() => {

      const fetchData = async () => {
          const data = await getProducts();
          // console.log("datatata", data)
          setLeng(data.length + 1.0)
        }

      fetchData()

  }, [type, collection])

  return (
    <div className='shop-main-container'>
        <h1 style={{fontSize: "4rem", textAlign: "center", textAlign: "center", marginTop: "2vh", fontSize:"3rem", color: "black !important", color: "#9b64ff", fontWeight: "800"}}>
          {type ? type.value : "All Products"}
          {collection ? collection.title : ""}
        </h1>
        {/* {products && products.map((obj, idx) => { */}
        {/* return ( */}
            <div style={{display: "flex", height: "50%", alignSelf: 'flex-start', marginLeft: "auto", marginRight: "auto", justifyContent: "center", flexWrap: 'wrap'}}>
                {products && products.map((obj, idx) => {
                    return (
                        
                        <Product_Card product={obj}/>

                    )
                })}
            </div>

        {/* ) */}
      {/* })} */}
    </div>
  )

}
