import { render } from '@testing-library/react'
import "../css/art.css"
import { Canvas, useFrame } from '@react-three/fiber'
import React, { useRef, useState, useEffect } from 'react'
import styles from '../css/art.css'

import {Parallax, ParallaxLayer} from '@react-spring/parallax'
// import { useScroll } from 'framer-motion'
import {   useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef, } from '@react-spring/web'
import ImageSlider from '../components/imageSlider'

export default function Art() {

  const containerRef = React.useRef()

  const  [p_layer, setP_layer] = useState(0);


  const [props, api] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      delay: 1200,
    }),
    []
  )

  const [open, set] = useState(false)

  const springApi = useSpringRef()
  const { size, ...rest } = useSpring({
    ref: springApi,
    config: config.stiff,
    from: { height: '100%', width: '65%', background: 'hotpink' },
    to: {
      height: open ? '150%' : '100%',
      width: open ? `100%` : '65%',
      background: open ? 'red' : 'hotpink',
    },
  })

  const transApi = useSpringRef()
  // const transition = useTransition(open ? data : [], {
  //   ref: transApi,
  //   trail: 400 / data.length,
  //   from: { opacity: 0, scale: 0 },
  //   enter: { opacity: 1, scale: 1 },
  //   leave: { opacity: 0, scale: 0 },
  // })

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springApi, transApi] : [transApi, springApi], [
    0,
    open ? 0.1 : 0.6,
  ])

  return (
    // <div className='main-container-art' >
      <Parallax pages={6} style={{zIndex: '10', top: '0', left: '0', background: "white"}} >
        <ParallaxLayer
        offset={0}
          style={{
            background: 'white',
            backgroundSize: 'cover'
          }}
          factor={1}
          
        >
          <animated.div id={`huge-container`} className={`flex j-center a-center`} style={props}>
            <h1 className='huge'>KUMI.</h1>
          </animated.div>
          {/* <animated.h1 ref={ref} style={springs} id='huge'>KUMI</animated.h1> */}
            
        </ParallaxLayer>

        <ParallaxLayer
          // offset={0.1}
          factor={0}
          style={{
            background: "transparent",
            backgroundSize: 'cover'
          }}
          
        >
          <div className='flex j-center art-container'
          >
              <h3 id='welcome-text'>Welcome to</h3>
          </div>
        </ParallaxLayer>

        <ParallaxLayer 
          offset={1}
          style={{
            background: "black",
            backgroundSize: 'cover',
            width: "100%"
          }}
          factor={1}


        >
          <div className='flex j-center a-center art-container'>
              {/* <img src={`https://cxeatafqhdriiwsmbiji.supabase.co/storage/v1/object/public/kumiworld/misc/waterfall1.jpg?t=2023-08-18T04%3A36%3A25.871Z`} /> */}
              {/* <h1>Follow Me</h1>
               */}
               <ImageSlider />
               
          </div>
          
        </ParallaxLayer>

        <ParallaxLayer 
          offset={2}
          style={{
            background: 'url("https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/kumi_text.png?t=2023-08-31T19%3A54%3A59.525Z")',
            backgroundSize: 'cover',
            width: "100%"
          }}

          factor={5}
          speed={0.55}
          className='flex j-center a-center '
        >
          {/* <h1 className='huge' style={{rotate: "90deg"}}>KUMI</h1>
          <h1 className='huge' style={{rotate: "90deg"}}>KUMI</h1> */}
          
        
        </ParallaxLayer>

        <ParallaxLayer 
          offset={2}
          style={{
            background: 'transparent',
            backgroundSize: 'cover',
            width: "100%"
          }}

          factor={0.5}
          speed={0.1}
          className='flex j-center a-center'
        >
          <h1>Kara</h1>
          
        
        </ParallaxLayer>
        <ParallaxLayer 
          offset={2.5}
          style={{
            background: 'black',
            backgroundSize: 'cover',
            width: "60%",
            marginLeft: "15%"
            // height: p_layer,
          }}
          speed={-0.06}

          factor={0.5}
          className='flex j-center a-center parallax-layer-div-art '
          // onClick={() => {setP_layer('100vh !important')}}
        >
          {/* <animated.div className={'art-container'}
          > */}
            <img style={{height: `50%`}} src={`https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/shirt_0_0-removebg-preview.png?t=2023-08-31T19%3A54%3A15.140Z`}/>
            <p>This is ART, CRAZY work crazy rats, keep going, go next. Lorem Espilum lorem loi, epi epi loe oe, work hard</p>
          {/* </animated.div> */}
          
        
        </ParallaxLayer>

        <ParallaxLayer 
          offset={3.5}
          style={{
            background: 'red',
            backgroundSize: 'cover',
            width: "60%",
            marginLeft: "30%"
          }}
          speed={0.01}

          factor={0.5}
          className='flex j-center a-center parallax-layer-div-art'
        >
          <img style={{height: `50%`}} src={`https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/shirt_0_0-removebg-preview.png?t=2023-08-31T19%3A54%3A15.140Z`}/>
          <p>This is ART, CRAZY work crazy rats, keep going, go next. Lorem Espilum lorem loi, epi epi loe oe, work hard</p>
        
        </ParallaxLayer>

        <ParallaxLayer 
          offset={4.5}
          style={{
            background: 'black',
            backgroundSize: 'cover',
            width: "60%",
            marginLeft: "15%"
          }}
          speed={-0.03}

          factor={0.5}
          className='flex j-center a-center parallax-layer-div-art'
        >
          <img style={{height: `50%`}} src={`https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/shirt_0_0-removebg-preview.png?t=2023-08-31T19%3A54%3A15.140Z`}/>
          <p>This is ART, CRAZY work crazy rats, keep going, go next. Lorem Espilum lorem loi, epi epi loe oe, work hard</p>
        
        </ParallaxLayer>

        <ParallaxLayer 
          offset={5.5} 
          style={{
            background: 'black',
            backgroundSize: 'cover'
          }}
          factor={0.3}


        >
          <h1>Kyle</h1>
        
        </ParallaxLayer>



      </Parallax>
    // </div>
  )
}
