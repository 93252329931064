import React from 'react';
import ReactDOM from 'react-dom/client';
// import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Context from './context/userContext';
import { MedusaProvider, CartProvider } from "medusa-react"
import { QueryClient } from "@tanstack/react-query"

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <GoogleOAuthProvider clientId='1058952655796-e584h7vofllcvvrhuuck3842ktq59sfd.apps.googleusercontent.com'>
      <MedusaProvider           
        queryClientProviderProps={{ client: queryClient }}
        baseUrl="http://localhost:9000"
      >
        <CartProvider>
          <Context>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Context>       
        </CartProvider>
        
      </MedusaProvider>   
      
  </GoogleOAuthProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
