import React from 'react'
import { medusaClient } from "../utils/medusa_client";
import { UserContext } from "../context/userContext";
import {useContext, useState} from 'react'
import { Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { CookiesProvider, useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

import "../css/login.css"
export default function MyLogin() {
    const {session, setSession, supabase, cart, setCart, handleCreateCart, handleAddItem, handleAddAddress, handleAddShippingOption, cartId, setCartId, user, setUser, associateCustomer} = useContext(UserContext)
    const navigate = useNavigate()
    const [registered, setRegistered] = useState(false)
    const [myCustomer, setMyCustomer] = useState({
        email: "",
        password: "",
        first_name: "",
        last_name: "",
    })

    // console.log("document cookie", document.cookie)

    function loginExistingCustomer() {
        medusaClient.auth.authenticate({
            email: myCustomer.email,
            password: myCustomer.password,
          })
          .then(({ customer }) => {
            // console.log(customer.id)
            setUser(customer)
            associateCustomer(customer)
          })
    }
    function logoutCustomer () {
        medusaClient.auth.deleteSession()
        .then(() => {
            // success
            // console.log("Logged Out")
            setUser(0)
            
        })

    }
    function createNewCustomer () {
        // check if email exists already
        let { exists } = medusaClient.auth.exists(myCustomer.email)
        medusaClient.customers.create({
            email: myCustomer.email,
            password: myCustomer.password,
            first_name: myCustomer.first_name,
            last_name: myCustomer.last_name,
          })
          .then(({ customer }) => {
            // console.log(customer.id)
            setUser(customer)
            associateCustomer(customer)
          })
    }

    return (
        <div className='login-main-container'>
            {/* <h1> HELLLO</h1> */}
            {!user ?
                <div className='login-form-container'>
                    {registered ? 
                        <Form data-bs-theme="dark">
                            <Form.Group  className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" onChange={(e) => setMyCustomer(
                                    {
                                        ...myCustomer,
                                        email: e.target.value 
                                    }
                                    )}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' as="input" onChange={(e) => setMyCustomer(
                                    {
                                        ...myCustomer,
                                        password: e.target.value 
                                    }
                                    )}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control as="input" onChange={(e) => setMyCustomer(
                                    {
                                        ...myCustomer,
                                        first_name: e.target.value 
                                    }
                                    )}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control as="input" onChange={(e) => setMyCustomer(
                                    {
                                        ...myCustomer,
                                        last_name: e.target.value 
                                    }
                                    )}/>
                            </Form.Group>
                        </Form> 
                        :
                        <Form data-bs-theme="dark">
                            <Form.Group  className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="name@example.com" onChange={(e) => setMyCustomer(
                                    {
                                        ...myCustomer,
                                        email: e.target.value 
                                    }
                                    )}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type='password' as="input" onChange={(e) => setMyCustomer(
                                    {
                                        ...myCustomer,
                                        password: e.target.value 
                                    }
                                    )}/>
                            </Form.Group>
                        </Form>
                    }

                    {registered ? <Button onClick={createNewCustomer}>Register</Button> : <Button onClick={loginExistingCustomer}>Login</Button>}

                    <div className='login-bottom-button-group'>
                        <Button onClick={() => {setRegistered(false)}}>Login</Button>
                        <Button onClick={() => {setRegistered(true)}}>Register</Button>
                    </div>
                    {/* <Button>{registered ? `Register` : 'Login'}</Button> */}
                </div>
            :
                <>
                    {navigate(-1)}
                    <h1>Logging In</h1>
                </>
            }
        </div>
    )
}
