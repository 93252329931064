import React, { useRef, useState, useEffect } from 'react'
import styles from '../css/art.css'

import {Parallax, ParallaxLayer} from '@react-spring/parallax'

export default function About() {
  return (

    <div className='island-container' style={{position: "relative",top: '0', left: '0', background: "var(--bg-color-0)", paddingTop: "180px", alignItems: "center", display: "flex", flexDirection: "column", marginTop: "unset", marginBottom: "unset"}}>
      <h1 style={{marginBottom: "2%", color: "#ff7c53", fontWeight: "800", fontSize: "10rem"}}>
          About KUMI
      </h1>
      <div className='island text-island about' style={{justifyContent: "center"}}>
        <h4 style={{marginBottom: "50px"}} className='secondary-color'>Our Inspiration:</h4>
          <h1 className='secondary-color mid-text'>
          At KUMI, our story is woven with love for cats. Our brand is named after these graceful creatures that have captured our hearts with their elegance, playfulness, 
          and, of course, their impeccable sense of style. Just like the charming feline companions that inspire us, 
          KUMI is all about showcasing your unique style through colorful, cute, and exceptionally designed clothing.
        </h1>
      </div>
      <div className='island text-island about' style={{justifyContent: "center"}}>
        <h4 style={{marginBottom: "50px"}} className='secondary-color'>Express Yourself with Color:</h4>
          <h1 className='secondary-color mid-text'>
          Our mission is to infuse your wardrobe with vibrancy and personality. 
          We believe that fashion is a powerful means of self-expression, 
          and that's why we've curated a collection that bursts with an array 
          of colors, patterns, and designs. Whether you're looking to make a statement or simply 
          brighten up your day, KUMI has the perfect ensemble for every occasion.


        </h1>
      </div>
      <div className='island text-island about' style={{justifyContent: "center"}}>
        <h4 style={{marginBottom: "50px"}} className='secondary-color'>Craftsmanship and Quality:</h4>
          <h1 className='secondary-color mid-text'>
          Quality is at the heart of what we do. We take pride in crafting our 
          garments with the utmost care and attention to detail. Every piece 
          from KUMI is a testament to our commitment to delivering not only 
          visually appealing clothing but also durable and comfortable apparel 
          that you can enjoy for years to come.

        </h1>
      </div>
      <div className='island text-island about' style={{justifyContent: "center"}}>
        <h4 style={{marginBottom: "50px"}} className='secondary-color'>Join the KUMI Community:</h4>
          <h1 className='secondary-color mid-text'>
          KUMI is more than just a clothing brand; 
          it's a community of fashion enthusiasts who 
          share a passion for style and self-expression. 
          We encourage you to join our community, connect 
          with like-minded individuals, and embark on a fashion journey that celebrates your uniqueness.


        </h1>
      </div>
    </div>

  )
}
