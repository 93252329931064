import React, { useEffect, useState } from 'react'
import { 
    CardElement,
    useElements,
    useStripe,
  } from "@stripe/react-stripe-js"
  import {PaymentElement} from '@stripe/react-stripe-js';
  import { UserContext } from "../context/userContext";
  import {useContext} from 'react'
  import { medusaClient } from "../utils/medusa_client";
  import {Elements} from '@stripe/react-stripe-js';
  import {loadStripe} from '@stripe/stripe-js';
import Confirmation from '../components/confirmation';
  
  const stripePromise = loadStripe("pk_test_51LokTiDjPQWfjKKWLZ8uyClroULXk5Aolq3FzJYNFBVY9EIRWYhc6IB412FUquwXNUcbM4KdplEAKb1nuvxnGD2g00qJyBli0u");

export default function OrderConfirmation() {
    // stripe.retrievePaymentIntent
    const {session, setSession, supabase, cart, setCart, handleCreateCart, handleAddItem, handleAddAddress, handleAddShippingOption} = useContext(UserContext)
    // console.log('cart', cart)
    const [message, setMessage] = useState(null);
    const user = useContext(UserContext)
    const [clientSecret, setClientSecret] = useState("");
    
  return (
    <div style={{paddingTop: "150px", position: "relative", display: "flex", flexDirection: "column"}}>
      {/* <h1>Order Page</h1> */}
      {console.log("alda")}
      {cart &&
        <Elements 
            stripe={stripePromise} >
            <Confirmation cart_id={cart.id} clientSecret={clientSecret} setClientSecret={setClientSecret}/>
        </Elements>
      }
    </div>
  )
}
