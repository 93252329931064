import "../css/start.css"
import SliderItem from '../components/slider';
import React from "react"
import { UserContext } from "../context/userContext";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { motion } from "framer-motion";

import "../css/home.css"

import { useForm } from "react-hook-form";
import { useState, useEffect, useContext} from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

import Product_Card from "../components/product_card";
import Product_Row from "../components/product_row";

// import { medusaClient } from "../utils/medusa_client";

export default function Start() {
    const {session, setSessions, supabase, cart, setCart} = useContext(UserContext)

    // const [products, setProducts] = useState([]);

    // async function getAllProducts() {
    //     const res = await axios.get(`http://localhost:5004/api/getAllProducts`)
    //     console.log("data", res.data)
    //     // setProducts([...products,{"name": res.product_name, "price": res.product_price, "image": res.product_image, "id": res.product_id, "desc": res.product_desc, "size":res.product_size, "color": res.product_color, "class":res.product_productClass}])
        
    //     for (let item of res.data) {
    //         console.log("item", item)
    //         setProducts(products => [...products, {"name": item.product_name, "price": item.product_price, "image": item.product_image, "id": item.product_id, 
    //         "desc": item.product_desc, "size":item.product_size, "color": item.product_color, "class":item.product_class}])
    //     }

    //     // console.log("prod", products)
    //     return res
    // }
    // useEffect(() => {

        

    //     let data = getAllProducts()



    // }, [])

  return (
    <div className=''>
        <Product_Row />
    </div>  
  )
}
