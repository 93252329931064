import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { UserContext } from "../context/userContext";
import {useContext} from 'react'
import "../css/product_card.css"
import { useState, useEffect} from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
// import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";

// Photos from https://citizenofnowhe.re/lines-of-the-city
import { useRef } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue
} from "framer-motion";



const Product_Card = (props) => {
    return (
        <Link className="custom-nav-link" to={`/product/${props.product.id}`} state={{ product: props.product}}>
            <section className="">
                <div className="product-card-img-container" >
            {/* <img src={`/${id}.jpg`} alt="A London skyscraper" />
            */}
                    <img className="product_card-img" src={`${props.product.images[0].url}`}/> 

                </div>
                <div className="flex product_card-text_container" >
                    <h2>{`${props.product.title}`}</h2>

                    {/* <p className="product-card-desc">{`${props.product.desc}`}</p> */}

                    <h4>{`$${props.product.variants[0].prices[0].amount / 100}`}</h4>
                </div>
            

            </section>            
        </Link>

        // <Nav.Link href={`/product/${props.product.id}`} className="product_card-main-container">
        //     <img className="product_card-img" src={`https://cxeatafqhdriiwsmbiji.supabase.co/storage/v1/object/public/kumiworld/products/${props.product.image[0]}`}/> 


            
        // </Nav.Link>
    )
}

export default Product_Card