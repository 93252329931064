import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { UserContext } from "../context/userContext";
import {useContext} from 'react'
import "../css/navbar.css"
import { useState, useEffect, useMemo} from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'; 
import {BsFillCartFill} from "react-icons/bs"
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Configure } from 'react-instantsearch';
import { useParams } from "react-router-dom";

// import Cart from "./cart";
import { useNavigate } from "react-router-dom";

import { medusaClient } from "../utils/medusa_client";
import { SearchBox, Hits  } from 'react-instantsearch';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { AiOutlineClose, AiOutlineSearch } from 'react-icons/ai';
import Alert from 'react-bootstrap/Alert';

// import algoliasearch from 'algoliasearch/lite';
// import { InstantSearch } from 'react-instantsearch';BsFillGrid3X3GapFill

// const searchClient = algoliasearch('Q48P83F2H8', 'a0e7bc286ce7a8454a2a98ad8e84113c');
function Hit({ hit }) {
    const [tmpProduct, setTmpProduct] = useState()
    
    function getProductById (id) {
        return medusaClient.products.retrieve(id)
            .then(({ product }) => {
            setTmpProduct(product)
        });
        
        }
    if (tmpProduct == null) {
        getProductById(hit.objectID)
    }
    return (
      <Link style={{display: "flex"}} to={`/product/${hit.objectID}`} state={{ product: tmpProduct}}>
        <img style={{width: '25%'}} src={hit.thumbnail}></img>
        <p>{hit.title}</p>
      </Link>
    );
  }
  
const MyNavbar = () => {
    const {session, setSessions, supabase, cart, setCart, createCart, user, setUser, logoutCustomer, loggedIn} = useContext(UserContext)
    const [ profile, setProfile ] = useState(null);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState("")
    const [types, setTypes] = useState("")
    const [collections, setCollections] = useState("")
    const [displayHit, setDisplayHit] = useState("invisible")
    const [mobileDisplay, setMobileDisplay] = useState(false)
    const [dropDownShop, setDropDownShop] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [searchBox, setSearchBox] = useState(false)
    const [alert, setAlert] = useState([false, null])
    const [cartQuantity, setCartQuantity] = useState(0)
    useEffect(() => {
    // load the types
      const fetchTypes = async () => {
        let types = await medusaClient.productTypes.list()
        let {collections} = await medusaClient.collections.list()
        setTypes(types)
        setCollections(collections)
      }

      fetchTypes()
    }, [])
    
    const mediaQuery = useMemo(() => window.matchMedia("(max-width: 750px)"), ["(max-width: 750px)"]);
    const [match, setMatch] = useState(mediaQuery.matches);
    
    useEffect(() => {
        const onChange = () => setMatch(mediaQuery.matches);
        mediaQuery.addEventListener("change", onChange);
    
        return () => mediaQuery.removeEventListener("change", onChange);
    }, [mediaQuery]);


    useEffect(() => {
        function getCartQuantity() {
            let sum = 0
            if (!cart) {
                return 0
            }
            // console.log(cart.items)
            for (const obj of cart.items){
                sum += obj.quantity
            }
            return sum
        }

        setCartQuantity(getCartQuantity())
    }, [cart])

    return (
        <>
            <Navbar variant="dark" style={{height: "fit-content", minHeight: "136px"}}>
                {alert && alert[0] && 
                    <Alert variant="warning" onClose={() => setAlert([false, null])}>
                        {/* {console.log("lmao", alert[1])} */}
                        a
                    </Alert>
                }
                <Container className="flex" id="navbar" style={{height: "100%", width: "100%"}}>
                    {match ? 
                        <Nav className="me-auto flex navContainer width-100 mobile-navbar" 
                        activeKey="/"
                        style={{height: "100%", width: "100%"}}
                        >
                            <div className="flex width-100 nav-top" style={{height: "100%", width: "100%"}}>
                                
                                <div style={{width: "25%", display: "flex", justifyContent: "center"}} >
                                    <button onClick={(e) => {setMobileDisplay(true)}}><BsFillGrid3X3GapFill style={{color: "black"}}/></button>
                                </div>
                                {mobileDisplay && 
                                    <div className="side-navbar"> 
                                        
                                        <div className="side-navbar-header"> 
                                            <button style={{ height: "fit-content", justifyContent: "center"}} className="navbar-mobile" onClick={(e) => {setMobileDisplay(false)}}><AiOutlineClose size={40} style={{color: "white"}}/></button>
                                            <button onClick={(e) => {setDropDownShop(!dropDownShop)}} id="navbar-shop" className="navbar-mobile">
                                                <a className="nav-link mobile-text" style={{borderBottom: "solid 1px white", color: "white"}}>
                                                    <p>
                                                        Shop
                                                    </p>
                                                    <p>
                                                        +
                                                    </p>
                                                </a>  
                                                {dropDownShop &&
                                                    <div className="navbar-shop-dropdown-mobile">
                                                        <Link key={Math.random()} className="nav-link mobile-text" style={{textAlign: "right", color: "white"}} to={`/shop/all`} state={{type: ""}}>
                                                            
                                                            <p style={{paddingLeft: "3%"}}>
                                                                All
                                                            </p>
                                                        </Link>
                                                        {types && types.product_types.map((obj, idx) => {
                                                            return (
                                                                <Link key={Math.random()} className="nav-link mobile-text" style={{textAlign: "right", color: "white"}} to={`/shop/${obj.value}`} state={{type: obj}}>
                                                                    <p style={{paddingLeft: "3%"}}>{obj.value}</p>
                                                                </Link>
                                                            )
                                                        })}
                                                    </div> 
                                                }
                                            </button>
                                            
                                            {/* <button className="navbar-mobile">
                                                <Nav.Link href="/art" className="mobile-text" style={{borderBottom: "solid 1px white", color: "white"}}><p>Art</p><p>+</p></Nav.Link>
                                            </button> */}
                                            <button className="navbar-mobile">
                                                <Nav.Link href="/about" className="mobile-text" style={{borderBottom: "solid 1px white", color: "white"}}><p>About</p><p>+</p></Nav.Link>
                                            </button>
                                        </div>
                                        
                                            
                                        
                                        <ul className="side-navbar-footer">
                                            <li className="navbar-mobile">
                                                <a href="/" className="mobile-text" style={{ color: "white"}}><p>Terms of Service</p></a>
                                            </li>
                                            <li className="navbar-mobile">
                                                <a href="/" className="mobile-text" style={{ color: "white"}}><p>Privacy Policy</p></a>
                                            </li>

                                            <li className="navbar-mobile">
                                                <a href="/" className="mobile-text" style={{ color: "white"}}><p>Check Order</p></a>
                                            </li>

                                            <li className="navbar-mobile">
                                                <a href="/" className="mobile-text" style={{ color: "white"}}><p>Ethics</p></a>
                                            </li>
                                        </ul>

                                    </div>
                                }

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    height: "100%",
                                    width: "75%"

                                }}>
                                    <Nav.Link href="/" className="kumi_logo_img" style={{height: "100%", width: "100%"}}>
                                        {/* <h1>hest</h1> */}
                                        <img style={{height: "100%", width: "100%", objectFit: "contain"}} src={`https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/kumi_logo_tran.png?t=2023-08-31T19%3A53%3A47.337Z`}/>
                                    </Nav.Link>
                                </div>
                                
                                <div className="navbar-button-container" style={{width: "25%"}}>

                                    {
                                        loggedIn ? <Button className="fit-content" variant="light" onClick={logoutCustomer}>Logout </Button> : <Nav.Link href="/login" >Login</Nav.Link>
                                    }
                                    <button className="search-button-mine" onClick={() => {setSearchBox(!searchBox)}}><AiOutlineSearch /></button>
                                    
                                    
                                    <Button className="fit-content" variant="light" onClick={handleShow}>
                                        <BsFillCartFill />
                                        {cart && cart.items.length}
                                    </Button>
                                </div>
                            </div>
                            <div className="flex nav-bot">
                            {
                                searchBox &&
                                <div className="search-container flex width-100">
                                    <SearchBox classNames={{
                                        root: "search-root",
                                        input: "search-input",
                                        reset: "search-reset",
                                        resetIcon: "search-reset-icon",

                                        
                                    }} value={'search_query'} searchAsYouType={false} onSubmit={() => {setDisplayHit("")}}/>
                                    {<Hits className={`hits ${displayHit}`} hitComponent={Hit} />} 
                                </div>
                            }
                            </div>
                        </Nav>
                    : 
                        <Nav className="me-auto flex navContainer width-100" 
                            activeKey="/"
                            >
                            <div className="flex width-100 nav-top">
                                <div className="search-container flex">
                                    <SearchBox classNames={{
                                            
                                            root: "search-root",
                                            search: "search-input",
                                            submit: "search-submit",
                                            reset: "search-reset",
                                            resetIcon: "search-reset-icon",
                                            input: "search-input",

                                        }} value={'search_query'} searchAsYouType={false} onSubmit={() => {setDisplayHit("")}}/>
                                    {<Hits className={`hits ${displayHit}`} hitComponent={Hit} />} 
                                </div>
                                
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    width: "100%"

                                }}>
                                    <Nav.Link href="/" className="kumi_logo_img" style={{height: "50px"}}>
                                        <img style={{height: "inherit"}} src={`https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/kumi_logo_tran.png?t=2023-08-31T19%3A53%3A47.337Z`}/>
                                    </Nav.Link>
                                    <div className="flex width-100 nav-bot">
                                        <div></div>
                                        <div className="flex navbar-center-buttons">
                                            <div id="navbar-shop">
                                                <Link className="nav-link" to={`/shop/all`} state={{type: "", color: "white"}} key={Math.random()} style={{borderBottom: "solid 3px rgba(248,200,220,1)"}}>
                                                    Shop
                                                </Link>   
                                                <div className="navbar-shop-dropdown">
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <h4 className="dropdown-title">Types:</h4>
                                                        {types && types.product_types.map((obj, idx) => {
                                                            return (
                                                                <Link style={{color: "white"}} className="nav-link" to={`/shop/${obj.value}`} state={{type: obj, collection: "-1"}} key={Math.random()}>{obj.value}</Link>
                                                            )
                                                        })}
                                                    </div>
                                                    <div style={{display: "flex", flexDirection: "column"}}>
                                                        <h4 className="dropdown-title">Collections:</h4>

                                                        {collections && collections.map((obj, idx) => {
                                                            return (
                                                                <Link style={{color: "white"}} className="nav-link" to={`/shop/${obj.title}`} state={{type: "-1", collection: obj}} key={Math.random()}>{obj.title}</Link>
                                                            )
                                                        })}
                                                    </div>
                                                </div> 
                                            </div>
                                            
                                            {/* <Nav.Link href="/art" style={{borderBottom: "solid 3px rgba(174,198,207,1)", color: "white"}}>Art</Nav.Link> */}
                                            <Nav.Link href="/about" style={{borderBottom: "solid 3px rgba(248,200,220,1)", color: "white"}}>About</Nav.Link>
                                            
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                                
                                <div className="navbar-button-container">

                                    {
                                        loggedIn ? <Button className="fit-content" variant="light" onClick={logoutCustomer}>Logout </Button> : <Nav.Link href="/login" >Login</Nav.Link>
                                    }
                                    
                                    <Button className="fit-content" variant="light" onClick={handleShow}>
                                        <BsFillCartFill />
                                        {cart && cartQuantity}
                                    </Button>
                                </div>
                            </div>
                        </Nav>
                    }
                    

                </Container>
            </Navbar>
            <Modal className="flex navbar-custom" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cart</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                    <div className="flex cart-main-container">
                        {cart && cart.items.map((obj, idx) => {
                            let quan = obj.quantity
                            return (
                                <div className="cart-text-container">
                                    <img className="cart-image" src={`${obj.thumbnail}`}/>
                                    
                                    <div style={{display: "flex", flexDirection: "column", width: "45%"}}> 
                                        <p>
                                            {obj.title}
                                        </p>
                                        <p>
                                            {obj.variant.title}
                                        </p>
                                        <div style={{display: "flex", flexDirection: "column"}}> 
                                            
                                            <p>${obj.total / 100}</p>
                                            <div style={{display: "flex", justifyContent: "center", alignItems:"center"}}> 
                                                <button onClick={() => {
                                                    medusaClient.carts.lineItems.update(cart.id, obj.id, {quantity: obj.quantity-1})
                                                    .then(({ cart }) => setCart(cart))
                                                    .catch((error) => setAlert([true, error]))
                                                    }}>
                                                    -
                                                </button>
                                                {/* <input type="number" value={quan}/> */}
                                                <div style={{width: "20%", textAlign: "center"}}>{obj.quantity}</div>
                                                <button onClick={() => {
                                                    medusaClient.carts.lineItems.update(cart.id, obj.id, {quantity: obj.quantity+1})
                                                    .then(({ cart }) => setCart(cart))
                                                    .catch(({res}) => console.log(res))
                                                    }}>
                                                    +
                                                </button>
                                                    
                                                <button onClick={() => {medusaClient.carts.lineItems.delete(cart.id, obj.id)
                                                    .then(({ cart }) => setCart(cart))}}>
                                                    X
                                                </button>
                                            </div>
                                            {/* <input>
                                                <button type="button" class="increment-quantity" aria-label="Add one" data-direction="1"><span>&#43;</span></button> medusaClient.carts.lineItems.update(cart.id, obj.id, {quantity: quantity})
                                                <button type="button" class="decrement-quantity" aria-label="Subtract one" data-direction="-1" disabled="disabled"><span>&#8722;</span></button>
                                            </input> */}
                                        </div>

                                        
                                    </div>
                                    
                                </div>
                                
                            )
                        })}
                        
                    </div>
                    <Nav.Link style={{width: "100%", minHeight: "10%", maxHeight:"12%", justifyContent: "center", alignItems: "center", background: "lightblue", display: "flex"}} href="/checkout" >Checkout</Nav.Link>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MyNavbar