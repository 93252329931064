import React from 'react'
import { useState, useEffect, useContext} from 'react'
import { useForm } from "react-hook-form";
import { v4 as uuidv4} from 'uuid';
import axios from 'axios'
import { UserContext } from "../context/userContext";
import Button from 'react-bootstrap/Button';
import "../css/admin.css"
export default function Admin() {
    const {session, setSessions, supabase, cart, setCart} = useContext(UserContext)

    const [name, setName] = useState()
    const [price, setPrice] = useState()
    const [image, setImage] = useState()
    const [id, setId] = useState()

    //
    const [desc, setDesc] = useState('temp desc')
    const [size, setSize] = useState('S')
    const [color, setColor] = useState('red')
    const [productClass, setProductClass] = useState()
    //

    const { register, handleSubmit } = useForm();

    async function addProduct() {
        // setProducts([...products,{"name": name, "price": price, "image": image, "id": id, "desc": desc, "size":size, "color": color, "class":productClass}])
        let temp_image = uuidv4()
        let image_uuid = []

        for (let item of image) {
            let temp = uuidv4()
            image_uuid.push(temp)
        }


        const res = await axios.post(`http://localhost:5004/api/createProduct`, {
            "name": name, 
            "price": price, 
            "image": image_uuid, 
            "id": id, 
            "desc": desc, 
            "size":size, 
            "color": color, 
            "class": productClass
        })
        // console.log("res", res)


        for (let item in image) {
            const{ data, error } = await supabase.storage.from('kumiworld').upload("products/" + image_uuid[item], image[item])        
            // console.log(data, error)
        }
        
    }
  return (
    <div className='main-container admin-main-container'>
      <form className="home-form">
            file<input name="img" type="file" onChange={(e) => {
                e.preventDefault() 
                // console.log(e.target.files)
                setImage(e.target.files)

                }} multiple/>
            name<input name="name" type="text" value={name} onChange={(e) => {
                e.preventDefault() 
                setName(e.target.value)
                }}/>
            price<input name="price" type="number" value={price} onChange={(e) => {
                e.preventDefault() 
                setPrice(e.target.value)
                
                }}/>
            desc<input name="desc" type="text" value={desc} onChange={(e) => {
                e.preventDefault() 
                setDesc(e.target.value)

                }}/>
            size<input name="size" type="text" value={size} onChange={(e) => {
                e.preventDefault() 
                setSize(e.target.value)
                }}/>
            color<input name="color" type="text" value={color} onChange={(e) => {
                e.preventDefault() 
                setColor(e.target.value)
                
                }}/>
            class<input name="productClass" type="text" value={productClass} onChange={(e) => {
                e.preventDefault() 
                setProductClass(e.target.value)
                
                }}/>
                
            <Button onClick={addProduct}>Submit</Button>
        </form>
    </div>
  )
}
