import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { UserContext } from "../context/userContext";
import {useContext} from 'react'
import "../css/navbar2.css"
import { useState, useEffect} from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'
import Supabase_Client from "./supabase_client";
import Modal from 'react-bootstrap/Modal'; 
import {BsFillCartFill} from "react-icons/bs"
import { Button } from "react-bootstrap";
const MyNavbar2 = () => {
    const {session, setSessions, supabase, cart, setCart} = useContext(UserContext)
    const [ profile, setProfile ] = useState(null);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const help = async (e) => {
        // console.log("in help")

        e.preventDefault()

        const url = "http://localhost:5004/auth/google"

        window.open(url, "_self")
        // const res = await axios.get ()
        // const res = await axios.post("http://localhost:5004/", {username: "andy946", password:"test123"}, {withCredentials: true})

        // const data = await res.json()
        // console.log('data', res)
    }


    return (
        <>
            <Navbar variant="dark">
                {/* <h1 >KUMI</h1> */}
                <div className="navbar-image"></div>
                <div className="navbar-link-container">
                    <h2>CATALOGUE</h2>
                    <h2>COLLECTION</h2>
                    <h2>ART</h2>

                </div>
            </Navbar>
            {/* <Modal className="flex navbar-custom" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
            </Modal> */}
        </>
    )
}

export default MyNavbar2