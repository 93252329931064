import React, { useEffect, useState } from 'react'
import { 
    CardElement,
    useElements,
    useStripe,
  } from "@stripe/react-stripe-js"
  import {PaymentElement} from '@stripe/react-stripe-js';
  import { UserContext } from "../context/userContext";
  import {useContext} from 'react'
  import { medusaClient } from "../utils/medusa_client";
  import {loadStripe} from '@stripe/stripe-js';
  import "../css/confirmation.css"  
  import Form from 'react-bootstrap/Form';
  import Button from 'react-bootstrap/Button';

  const stripePromise = loadStripe("pk_test_51LokTiDjPQWfjKKWLZ8uyClroULXk5Aolq3FzJYNFBVY9EIRWYhc6IB412FUquwXNUcbM4KdplEAKb1nuvxnGD2g00qJyBli0u");
  
export default function Confirmation({cart_id}) {
    // stripe.retrievePaymentIntent
    const [message, setMessage] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [success, setSuccess] = useState(false)
    const [order, setOrder] = useState()
    const [displayOrderInput, setDisplayOrderInput] = useState(false)
    const [orderNumber ,setOrderNumber] = useState()
    const [validated, setValidated] = useState(false);

    // const {session, setSession, supabase, cart, setCart, handleCreateCart, handleAddItem, handleAddAddress, handleAddShippingOption} = useContext(UserContext)
    // console.log("andyu")
    const stripe = useStripe()
    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        // console.log("clientsecret",clientSecret)
        if (!clientSecret) {
            setDisplayOrderInput(true)
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        //   console.log("intent", paymentIntent)
            switch (paymentIntent.status) {
            case "succeeded":
                setMessage("Payment succeeded!");
                break;
            case "processing":
                setMessage("Your payment is processing.");
                break;
            case "requires_payment_method":
                setMessage("Your payment was not successful, please try again.");
                break;
            case "requires_capture":
                setMessage("requires capture, but successful")
                medusaClient.carts.complete(cart_id, {expand: "items,billing_address,shipping_address,customer"}).then(
                  (cart) => {
                    let tmp = cart
                    // console.log("cart resp", cart)
                    localStorage.removeItem("cart_id")
                    if (tmp.type == "order") {
                        setOrder(tmp.data)
                        setSuccess(true)
                        // console.log('tmp', tmp)
                    }
                    
                  }
                )
                break
            default:
                setMessage("Something went wrong.");
                break;
            }
        });
    }, [stripe]);

    const handleSubmit = (event) => {
        
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        //   console.log('false')
        } else if(form.checkValidity() === true) {
            event.preventDefault()
            medusaClient.orders.retrieve(orderNumber)
            .then(({ order }) => {
                setOrder(order)
                setSuccess(true)
            });

            // console.log("truere")
        }
        setValidated(true);
      };
    
  return (

        <div className='order-container' style={{position: "relative", display: "flex", flexDirection: "column"}}>
            {/* {message} */}

            {displayOrderInput && 
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group md="4" controlId="validationCustom01">
                        <Form.Label>Order Number</Form.Label>
                        <Form.Control
                        required
                        type="text"
                        placeholder="123123123123"
                        defaultValue=""
                        onChange={(e) => {
                            setOrderNumber(e.target.value)
                        }}
                        />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    </Form.Group>
                    <Button type="submit" >Submit form</Button>

                </Form>
            }
            {success && order ?
                // success
                <div className='success'>

                        <div className='order-text-container'>
                            <h4>Thank You For Your Order!</h4>
                            <p className='fs-85'>Hello {order.shipping_address.first_name}, your order is on the way! We hope you enjoyed shopping with us.</p>
                        </div>
                        <div className='order-text-container'>
                            <div className='order-shipping-container'>
                                <h4>Shipping Address:</h4>
                                <p className='fs-85'>{order.shipping_address.address_1}</p>
                                <p className='fs-85'>{order.shipping_address.address_2}</p>
                                <p className='fs-85'>{order.shipping_address.city} {order.shipping_address.province} {order.shipping_address.country_code} {order.shipping_address.postal_code}</p>
                            </div>
                            <div className='order-delivery-container'>
                                <h4>Delivery Info:</h4>
                                <p className='fs-85'>Delivery Info will be emailed to {order.email}</p>
                            </div>
                        </div>

                        <div className='order-text-container'>
                            <h4>What You Ordered:</h4>
                            <h5>Order Id: {order.id}</h5>
                            <h6>Current Status: {order.fulfillment_status}</h6>
                            {order && order.items.map((obj, idx) => {
                            return (
                                <div className="cart-text-container">
                                    <img className="cart-image" src={`${obj.thumbnail}`}/>
                                    
                                    <div style={{display: "flex", flexDirection: "column", width: "45%"}}> 
                                        <p>
                                            {obj.title}
                                        </p>
                                        <p>
                                            {obj.variant.title}
                                        </p>
                                        <div style={{display: "flex", flexDirection: "column"}}> 
                                            
                                            <p>${obj.total / 100}</p>
                                        </div>
                                        <p>Q: {obj.quantity}</p>
                                    </div>
                                </div> 
                            )
                            
                        })}

                        <h5>Total Price: ${order.total / 100} </h5>
                        </div>


                </div>
                : // SEPERATION SEPERATION
                <div className='failure'>

                </div>
            }
        </div>


  )
}
