import React from "react"
import { UserContext } from "../context/userContext";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import "../css/home.css"
import "../css/product_page.css"

import { useForm } from "react-hook-form";
import { useState, useEffect, useContext, useRef, useMemo} from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

import Product_Card from "../components/product_card";
import { Link, useAsyncError, useParams } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { color } from "framer-motion";
import { useCreateLineItem } from "medusa-react"

import { useLocation } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const Product = () => {
    const {session, setSessions, supabase, cart, handleCreateCart, handleAddItem} = useContext(UserContext)
    const scollToRef = useRef();

    let { product_id } = useParams()
    // console.log(product_id)
    let location  = useLocation();
    let product = location.state.product
    // if (product == null) {
    //     (async () => {
    //         const data = await getProductById(product_id);
    //         console.log("dataaaa", data)
    //         return data
    //     })
    // }



    // console.log("propducts test", product)
    const [productName, setProductName] = useState()
    const [productDesc, setProductDesc] = useState()
    const [productImage, setProductImage] = useState([])
    const [productPrice, setProductPrice] = useState()
    const [variants, setVariants] = useState([{}])
    const [size, setSize] = useState(-1)
    const [color, setColor] = useState()
    const [quantity, setQuantity] = useState(1)
    const [filter, setFilter] = useState()
    const [products, setProducts] = useState()
    const [variant, setVariant] = useState(0)
    const [selectedColor, setSelectedColor] = useState(-1)


    useEffect(() => {
        // console.log("FILTER", filter, products)
        if (filter && products) {

            
            // let results = Object.entries(products).filter()
            let results = products[filter[1]][filter[0]]
            // console.log("results", results)
        }
      }, [filter])
      useEffect(() => {
        // console.log("FILTER", filter)
        let dict = {}
        product.options.map((obj, idx) => {
            if (!dict[obj.title]) {
                dict[obj.title] = []
            }
            obj.values.map((obj2, idx) => {
                // {console.log("titlelel", obj2)}
                if (!dict[obj.title][obj2.value]) {
                    dict[obj.title][obj2.value] = []
                }
                // let tmp = {`${obj2.value}`: `${obj2.variant_id}`}
                dict[obj.title][obj2.value].push(obj2.variant_id)
            })
        })
        // console.log("DICT", dict)
        setProducts(dict)
      }, [])
    
    let dups = {}

    const mediaQuery = useMemo(() => window.matchMedia("(max-width: 1024px)"), ["(max-width: 1024px)"]);
    const [match, setMatch] = useState(mediaQuery.matches);
    
    useEffect(() => {
        const onChange = () => setMatch(mediaQuery.matches);
        mediaQuery.addEventListener("change", onChange);
    
        return () => mediaQuery.removeEventListener("change", onChange);
    }, [mediaQuery]);
    

    return (
        <div className="product-page-main-container" style={{background: "var(--bg-color)"}}>
            {match ? // if the screen is in mobile mode, display a carousel
                <div className="flex image-container mobile"> 
                        <Carousel data-bs-theme="dark" touch={true} interval={null}>
                            {product.images.map((obj, idx) => {
                                return (
                                    <Carousel.Item>
                                        <img className="product-page-images" src={`${obj.url}`} />
                                    </Carousel.Item>
                                )
                            })}
                        
                        </Carousel>

                </div>
                :
                <div className={`flex image-container`}>
                    {product.images.map((obj, idx) => {
                        return (
                            <img className="product-page-images" src={`${obj.url}`} />
                        )
                    })}
                    
                </div>
            }
            <div className="flex column text-container">
                {/* <div className="text-container-wrapper"> */}
                    <div className="flex column text-container-top">
                        <h2>{product.title}</h2>
                        <p>${product.variants[0].prices[0].amount / 100}</p>
                    </div>
                    
                    <div className="flex input-container">
                        <h4>Quantity</h4> <input className="text-container-input" type="number" min="1" onChange={(e) => {setQuantity(e.target.value)}}></input>

                    </div>

                    {product.options.map((obj, idx) => {
                            return (
                                <div className="color-container"> 
                                    <h4>{obj.title}</h4>
                                    <div className="flex option-container"> 
                                        {obj.values.filter(function(el2) {
                                            if (!filter) {
                                                return el2
                                            }
                                            // console.log("obj.title == filter[1]", el2.variant_id, el2.value, filter, products[filter[1]][filter[0]],products[filter[1]][filter[0]].includes(el2.variant_id) || obj.title == filter[1])
                                            if (products[filter[1]][filter[0]].includes(el2.variant_id) || obj.title == filter[1]) {
                                                return el2
                                            }
                                        }).filter(function(el) {
                                            var hash = el.value.valueOf();
                                            // console.log("el", el.option_id, hash)
                                            // console.log(product)
                                            var isDup = dups[hash];
                                            dups[hash] = true;
                                            return !isDup;
                                        }).map((obj2, idx) => {
                                            // {console.log("titlelel", obj)}
                                            if (obj.title == "Color") {
                                                // console.log("in color", obj2)
                                                return (
                                                    <button type="checkbox" className={`color-buttons myButtons ${obj2.value.toLowerCase()} ${obj2.value == selectedColor ? "selected" : ""}`} onClick={() => { 
                                                        setFilter([obj2.value, obj.title])
                                                        setSelectedColor(obj2.value)
                                                        // console.log('varid', obj2.variant_id)
                                                        setColor(obj2.variant_id)

                                                    }}></button>
                                                )
                                            }
                                            else {
                                                return (
                                                    <button type="checkbox" className={`size-buttons myButtons ${obj2.value.toLowerCase()}`} onClick={() => { 
                                                        // setFilter([obj2.value, obj.title])
                                                        // console.log('varid', obj2.variant_id)
                                                        setSize(obj2.variant_id)
                                                    }}>{obj2.value.slice(0,2)}</button>
                                                )
                                            }
                                        })}
                                    </div>
                                    
                                </div>


                                
                            )
                        })} 
                    <div className="desc-container">
                        <h5>Details: </h5>
                        <p>{product.description}</p>
                    </div>

                    <div className="flex button-container">
                        <Button className="black no-border" onClick={() => {
                            // console.log("varid 2", size==color)
                            if (size == color || size == -1 && color) {
                                handleAddItem({variant: color, quantity: quantity})
                            }
                            }}>Add to Cart</Button>
                        <Button className="black no-border">Save</Button>
                    </div>

                {/* </div> */}


            </div>
        </div>
    )
}

export default Product