import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { UserContext } from "../context/userContext";
import {useContext} from 'react'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { 
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

import { medusaClient } from "../utils/medusa_client";
import "../css/checkout.css"
// import CheckoutForm from "./CheckoutForm";
// import "./App.css";
import MyForm from "../components/form";
// import { set } from "mongoose";
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51LokTiDjPQWfjKKWLZ8uyClroULXk5Aolq3FzJYNFBVY9EIRWYhc6IB412FUquwXNUcbM4KdplEAKb1nuvxnGD2g00qJyBli0u");

export default function Checkout() {
  const [clientSecret, setClientSecret] = useState("");
  const {session, setSession, supabase, cart, setCart, handleCreateCart, handleAddItem, handleAddAddress, handleGuestEmail, handleAddShippingOption, cartId, setCartId} = useContext(UserContext)
  // const { updateCart } = useCart(cartId)

  const [customAddress, setCustomAddress] = useState(
    {
      company: '',
      first_name: '',
      last_name: '',
      address_1: '',
      address_2: '',
      city: '',
      country_code: '',
      province: '',
      postal_code: '',
      phone: '',
    }
  )

  const [shippingOption, setshippingOption] = useState()
  const [chosenShippingOption, setChosenShippingOption] = useState(0)
  const [displayPaymentStep, setDisplayPaymentStep] = useState(false)
  const [paymentSessions, setPaymentSessions] = useState()
  const [backButtonState, setBackButtonState] = useState(false)
  const [displayPaymentSession, setDisplayPaymentSession] = useState(false)
  const [displayForm, setDisplayForm] = useState(false)
  const [displayShipping, setDisplayShipping] = useState(false)
  const [chosenPaymentSession, setChosenPaymentSession] = useState()
  const [email, setEmail] = useState("")
  function tmpHandleShippingOption () {
    medusaClient.shippingOptions.listCartOptions(cart.id)
      .then(({ shipping_options }) => {
        // console.log(shipping_options.length)
        setshippingOption(shipping_options)
      })
  }

  async function handleShippingStuff() {
    await handleGuestEmail(email)
    await handleAddAddress(customAddress)
    let tmpShippingOption = await tmpHandleShippingOption()
    // console.log("TMp shpo", tmpShippingOption)
  } 

  function initPaymentStep () {
    medusaClient.carts.createPaymentSessions(cart.id)
    .then(({ cart }) => {
      // console.log(cart.payment_sessions)
      setPaymentSessions(cart.payment_sessions)
    }) 
    setDisplayPaymentStep(true)
  }

  function initPaymentSession () {
    medusaClient.carts.setPaymentSession(cart.id, {
      // retrieved from the payment session selected by the customer
      provider_id: chosenPaymentSession,
    })
    .then(({ cart }) => {
      // console.log(cart.payment_session)
      setCart(cart)
      setClientSecret(cart.payment_session.data.client_secret)
    })
  }

  function completeCart () {
    medusaClient.carts.complete(cart.id)
    .then(({ type, data }) => {
      // console.log('compl', type, data)
    })
      }

  const appearance = {
    theme: 'night',
  };

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      console.log('false')
    } else if(form.checkValidity() === true) {
      setDisplayForm(displayForm+1)
      handleShippingStuff()
      console.log("truere")
    }
    setValidated(true);
  };

  let steps = [displayForm, displayShipping, displayPaymentSession]  
  return (
    <div className="checkout">
      <div className="checkout-main-container">
        <Button disabled={displayForm==0 ? true : false} onClick={() => {
          if (displayForm != 0) {
            setDisplayForm(displayForm-1)
          }
        }}>Go Back</Button>
      {displayForm==0 && 
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>First name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="First name"
              defaultValue=""
              onChange={(e) => {
                setCustomAddress(
                  {
                    ...customAddress,
                    first_name: e.target.value 
                  }
                )
              }}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Last name"
              defaultValue=""
              onChange={(e) => {
                setCustomAddress(
                  {
                    ...customAddress,
                    last_name: e.target.value 
                  }
                )
              }}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <Form.Label>email</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="text"
                placeholder="Username"
                aria-describedby="inputGroupPrepend"
                onChange={(e) => {
                  setEmail(e.target.value)
                }} 
                required
              />
              <Form.Control.Feedback type="invalid">
                Please input email.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-3">
        <Form.Group as={Col} md="4" controlId="validationCustom13">
            <Form.Label>Address 1</Form.Label>
            <Form.Control type="text" placeholder="1234 Drive Rd" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  address_1: e.target.value 
                }
              )
            }}  required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid address.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom14">
            <Form.Label>Address 2</Form.Label>
            <Form.Control type="text" placeholder="City" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  address_2: e.target.value 
                }
              )
            }} />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom15">
            <Form.Label>Country Code</Form.Label>
            <Form.Control type="text" placeholder="us" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  country_code: e.target.value 
                }
              )
            }}  required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid country code.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom16">
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="City" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  city: e.target.value 
                }
              )
            }}  required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid city.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom04">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" placeholder="State" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  province: e.target.value 
                }
              )
            }}  required />
            
            <Form.Control.Feedback type="invalid">
              Please provide a valid state.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="3" controlId="validationCustom05">
            <Form.Label>Zip</Form.Label>
            <Form.Control type="text" placeholder="Zip" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  postal_code: e.target.value 
                }
              )
            }}  required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid zip.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom25">
            <Form.Label>Phone</Form.Label>
            <Form.Control type="text" placeholder="Phone" onChange={(e) => {
              setCustomAddress(
                {
                  ...customAddress,
                  phone: e.target.value 
                }
              )
            }} required />
          </Form.Group>
        </Row>
        <Button type="submit" >Submit form</Button>
      </Form>
    
      }
      {displayForm==1 && <div className="shipping-option-container">
        {shippingOption && 
          <>
            {shippingOption.map((obj, idx) => {
              return (
                <Button onClick={() => {setChosenShippingOption(obj.id)}}> {obj.name} </Button>
              )
            })}
          </>
        }
      </div>}
      {
        chosenShippingOption != 0 && displayForm==1 && 

        <Button onClick={(e) => {
            medusaClient.carts.addShippingMethod(cart.id, {
              option_id: chosenShippingOption
            }).then(({ cart }) => setCart(cart))
            setDisplayForm(displayForm+1)
            initPaymentStep()
          }}>Go to Payment Step</Button>

      }

      {displayForm==2 && <div className="payment-session-container">
        {
          paymentSessions && 
          <div>
            {paymentSessions.map((obj, idx) => {
              return (
                <Button onClick={() => {setChosenPaymentSession(obj.provider_id)}}>{obj.provider_id}</Button>
              )
            })}

            
          </div>
        }  
     
      </div>}
      {
        chosenPaymentSession && displayForm==2 &&
        <div>
          <Button onClick={() => {
            initPaymentSession()
            setDisplayForm(displayForm+1)}}>Finalize</Button>
        </div>
      } 
      {displayForm==3 && <div className="finalizeCart-container"> 
        <h3>Almost There!</h3>
        <div>
          {clientSecret && (
            <Elements stripe={stripePromise} options={{
              clientSecret,
            }}>
            <MyForm clientSecret={clientSecret} cartId={cartId} address={customAddress}/>
          </Elements>
          )}
        </div>
      </div>}

      </div>
      <div className="checkout-main-container checkout-cart">
      {cart && cart.items.map((obj, idx) => {
        return (
            <div className="cart-text-container">
                <img className="cart-image" src={`${obj.thumbnail}`}/>
                
                <div style={{display: "flex", flexDirection: "column", width: "45%"}}> 
                    <p>
                        {obj.title}
                    </p>
                    <p>
                        {obj.variant.title}
                    </p>
                    <div style={{display: "flex", flexDirection: "column"}}> 
                        
                        <p>${obj.total / 100}</p>
                        <div style={{display: "flex", justifyContent: "center", alignItems:"center"}}> 
                            <button onClick={() => {
                                medusaClient.carts.lineItems.update(cart.id, obj.id, {quantity: obj.quantity-1})
                                .then(({ cart }) => setCart(cart))
                                }}>
                                -
                            </button>
                            {/* <input type="number" value={quan}/> */}
                            <div style={{width: "20%", textAlign: "center"}}>{obj.quantity}</div>
                            <button onClick={() => {
                                medusaClient.carts.lineItems.update(cart.id, obj.id, {quantity: obj.quantity+1})
                                .then(({ cart }) => setCart(cart))
                                }}>
                                +
                            </button>
                        </div>
                    </div>
                </div>
            </div> 
        )
    })}
      </div>
    </div>
  );
}