import { render } from '@testing-library/react'
import "../css/product_row.css"
import { Canvas, useFrame } from '@react-three/fiber'
import styles from '../css/art.css'
// import Carousel from 'react-bootstrap/Carousel';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";

import {Parallax, ParallaxLayer} from '@react-spring/parallax'
// import { useScroll } from 'framer-motion'
import {   useTransition,
  useSpring,
  useChain,
  config,
  animated,
  useSpringRef, } from '@react-spring/web'
import ImageSlider from '../components/imageSlider'
import { useState, useEffect, useContext, useRef} from 'react'
import Product_Card from "../components/product_card";
import axios from 'axios'
import { UserContext } from "../context/userContext";

import { useLocation } from 'react-router-dom';
import Carousel from './carousel';
import { medusaClient } from "../utils/medusa_client";
import { useScroll } from 'framer-motion';

export default function Product_Row() {
    const scroller = useRef();
  
    const {session, setSessions, supabase, cart, setCart} = useContext(UserContext)
    const [page, setPage] = useState(5)
    const [products, setProducts] = useState();
    const [collections, setCollections] = useState();
    const [showIslands, setShowIslands]= useState(false)
    const [bgColor, setBgColor] = useState("blue")
    // const { products, isLoading } = useProducts()

    const [leng, setLeng] = useState(0)

    async function getProducts() {
      let tags = await medusaClient.productTags.list({value:"Featured"})
      let { collections } = await medusaClient.collections.list()

      // console.log("tags", tags.product_tags)
      let {products} = await medusaClient.products.list({ tags:[tags.product_tags[0].id]})
      setCollections(collections)
      setProducts(products)
      return products
    }


    useEffect(() => {

      const fetchData = async () => {
          const data = await getProducts();
          // console.log("datatata", data)
          setLeng(data.length + 1.0)
        }

      // let data = await getAllProducts()
      // window.addEventListener('scroll', listenScrollEvent);

      fetchData()

      // return () => window.removeEventListener('scroll', listenScrollEvent);

  }, [])


  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    console.log('asd',scrollPosition);

    if (scrollPosition < 2000) {
      setBgColor("blue")
    } else if (scrollPosition > 2000 && scrollPosition < 3600) {
      setBgColor("purple")
    } else if (scrollPosition > 3600) {
      setBgColor("black")
    }
  };
  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    }, []);

    const custom_factor = 1
    const speed_factor = 0.3
    const leng2 = 6
    // console.log('eng', leng)
  return (
    



      <div className={`product-row-main-container bgColor-${bgColor}`} style={{top: '0', left: '0', paddingTop: "var(--navbar-height)", overflow: "scroll"}}>
          {/* <ParallaxLayer 
            offset={0}
            style={{
              background: "var(--bg-color)",
            }}
            factor={5.6}>

          </ParallaxLayer> */}

          <div 
          style={{
            background: "transparent",
            display: 'flex',
            flexDirection: "column",
            // justifyContent: "center",
            alignItems: "center",
          }}
          >
            <div className='hero'>
              <div className='hero-text' style={{position: "absolute", display: "flex", flexDirection: "column", alignItems: "center", zIndex: "13"}}>
                <h1 style={{fontSize: "4vw", fontWeight: '800', textShadow: '4px -4px #4f7cff', textAlign: "center"}}>WHERE LIFE MEETS COLOR</h1>
                <Link className="nav-link" to={`/shop/all`} state={{type: "", color: "white"}} key={Math.random()} style={{fontSize: "2vw", fontWeight: '800', textShadow: '4px -4px #4f7cff', textAlign: "center", background: "white", paddingLeft: "2%", paddingRight: "2%"}}>
                    Shop All
                </Link>  
              {/* <button >ass</button> */}
              </div>

              <video autoPlay loop muted >
                <source src='https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/video-color.mp4?t=2023-09-05T00%3A56%3A44.603Z' type='video/mp4'></source>
              </video>
            </div>
            
          </div>

          

          <div
            // sticky={{start: 1.2, end:1.5}}
            style={{
              display: "flex",
              justifyContent: "center"
            }}
            className='island-container'
          >
              <div className='island text-island' style={{justifyContent: "center"}}>
                <h4 style={{marginBottom: "50px"}} className='secondary-color'>Our Design Philosophy:</h4>
                  <h1 className='secondary-color mid-text'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                </h1>
              </div>
          </div>

          {/* <ParallaxLayer
            offset={2}
            factor={5}
            speed={0.45}
            style={{
              background: "var(--bg-color-reversed)"
            }}
          >
        
          </ParallaxLayer> */}
          <div style={{display: "flex", position: "relative"}}>
            <h1 className='carousel-headers' style={{left: "1.5%"}}>Popular products</h1>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "row",
              height: "80vh"
            }}
          >
            
            <div class="arrow arrow-left">
                  <span></span>
                  <span></span>
                  <span></span>
            </div>
            
            <div className='flex collection-container'>
              <Carousel products={products} type={"products"}/>
            </div>

            <div class="arrow arrow-right">
                <span></span>
                <span></span>
                <span></span>
            </div>
            
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center"
            }}
            className='island-container'
          >
              <div className='island text-island' style={{}}>
                <h4 style={{marginBottom: "50px"}} className='secondary-color island-header'>Our Design Philosophy:</h4>
                  <h1 className='secondary-color mid-text'>
                  laboris nisi ut aliquip ex ea commodo consequat. 
                  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. 
                </h1>
              </div>
          </div>

          <div          
           
            style={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column"


          }}
          >
            <div className='hero'
              onMouseLeave={() => {
                setShowIslands(false)
              }}>
              <div style={{fontSize: "4vw", textAlign: "center", fontWeight: '800', textShadow: '4px -4px #4f7cff', position: "absolute", zIndex: "10"}}
                onMouseEnter={() => {
                  setShowIslands(true)
                }}
              >Summer Collection</div>
              <video autoPlay loop muted >
                <source src='https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/misc/pexels-engin-akyurt-6722950%20(1080p).mp4?t=2023-09-05T08%3A52%3A17.961Z' type='video/mp4'></source>
              </video>
              <div id='invisible-island-container'>
                {showIslands && <div className='island flex-1-0-33' style={{backgroundSize: "cover", backgroundImage: "url(https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/banners/summer)", width: "33%"}}>
                  <h4 style={{marginBottom: "50px"}} className='secondary-color'>Our Summer Thoughts</h4>
                </div>}
                {showIslands && <div className='island flex-1-0-33' style={{backgroundSize: "cover", backgroundImage: "url(https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/banners/summer2)", width: "33%"}}>
                  <h4 style={{marginBottom: "50px"}} className='secondary-color'>Our Summer Thoughts</h4>
                </div>}
                {showIslands && <div className='island flex-1-0-33' style={{backgroundSize: "cover", backgroundImage: "url(https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/banners/summer)", width: "33%"}}>
                  <h4 style={{marginBottom: "50px"}} className='secondary-color'>Our Summer Thoughts</h4>

                </div>}
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className='island-container'
          >
              <div className='island' style={{}}>
                <h4 style={{marginBottom: "50px"}} className='secondary-color island-header'>Our VALUES: </h4>
                  <h1 className='secondary-color mid-text-large'>
                    TEMP
                  </h1>
                  <h1 className='secondary-color mid-text-large'>
                    TEMPTEMP
                  </h1>
                  <h1 className='secondary-color mid-text-large'>
                    TEMP
                  </h1>
              </div>
          </div>

          <div
            style={{
              background: "transparent",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column"


          }}
          offset={4.75}
          factor={0.8}
          speed={0.1}>
            
            <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
              <h1 className='carousel-headers' style={{ left: "1.5%", position: "relative"}}>Other Collections</h1>
              <div class="arrow arrow-right mid-size collection">
                  <span></span>
                  <span></span>
                  <span></span>
              </div>
            </div>

            <div className='flex collection-container'>

              <Carousel products={collections} type={"collections"}/>
            </div>

          </div>
      </div>
    
    
    

  )

}
                                                                                                                                                      {/*rgb(174,203,225) rgb(243,210,237) rgb(213,194,234) rgb(247,237,168) */}
