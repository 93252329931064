import React from 'react'
import "../css/footer.css"
import Nav from 'react-bootstrap/Nav';
import { useState, useEffect, useMemo} from 'react'

export default function Footer() {

    const mediaQuery = useMemo(() => window.matchMedia("(max-width: 750px)"), ["(max-width: 750px)"]);
    const [match, setMatch] = useState(mediaQuery.matches);
    
    useEffect(() => {
        const onChange = () => setMatch(mediaQuery.matches);
        mediaQuery.addEventListener("change", onChange);
    
        return () => mediaQuery.removeEventListener("change", onChange);
    }, [mediaQuery]);

  return (
    <>
        {!match && <div className='footer'>
            <div className='flex footer-container'>
                <div className='footer-container'>
                    <Nav.Link href="/" >Shipping Policies</Nav.Link>
                    <Nav.Link href="/" >Terms of Service</Nav.Link>
                    <Nav.Link href="https://www.instagram.com/kumi._world/" >Instagram</Nav.Link>
                    <Nav.Link href="/order" >Check Order</Nav.Link>
                    <Nav.Link >Email: kumiworld@gmail.com</Nav.Link>
                    
                </div>
            </div>
        </div>}    
    </>

  )
}
