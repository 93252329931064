import { 
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {PaymentElement} from '@stripe/react-stripe-js';

import { UserContext } from "../context/userContext";
import {useContext, useEffect, useState} from 'react'
import { medusaClient } from "../utils/medusa_client";

export default function MyForm({ clientSecret, cartId, address }) {
  const {session, setSession, supabase, cart, setCart, handleCreateCart, handleAddItem, handleAddAddress, handleAddShippingOption} = useContext(UserContext)

  const stripe = useStripe()
  const elements = useElements()
  const [message, setMessage] = useState(null);

  async function handlePayment(e) {
    e.preventDefault()
    // console.log("wtf", elements.getElement(PaymentElement))
    return stripe.confirmPayment({
      elements,
      confirmParams: {
        // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
        return_url: 'https://www.kumi.world/order',
      },
    })
    .then((result) => {
      // console.log("resul", result)
    });

    
  }

  return (
    <form>
      <PaymentElement/>
      <button onClick={handlePayment}>Submit Order</button>
    </form>
  )
};