import React from "react"
import { UserContext } from "../context/userContext";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';

import "../css/home.css"

import { useForm } from "react-hook-form";
import { useState, useEffect, useContext} from 'react'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios'

import Product_Card from "../components/product_card";
import { v4 as uuidv4} from 'uuid';
import AnimatedBall from "../components/balls";
import { motion } from "framer-motion";
// import { motion } from '../../public/particles.json'
const Home = () => {
    const {session, setSessions, supabase, cart, setCart} = useContext(UserContext)
    const [x, setX] = useState(100);
    const [y, setY] = useState(0);
    const [rotate, setRotate] = useState(0);

    // console.log(1)
    // useEffect(() => {
    // //   console.log(x)
    //     setX(Math.random()*100)

    // }, [x])
    // particlesJS.load('particles-js', '../../public/particles.json', function() {
    //     console.log('callback - particles.js config loaded');
    //   });

    return (
        // <div className="main-container home-main-container">
        //     <div id="particles-js"></div>
        // </div>
        <div className="main-container home-main-container">
            <motion.div
                initial={{opacity:0}}
                animate={{opacity:1}}
                transition={{duration:2}}
                
            >
                <h1 className="large-button">
                    {`< Shop Now >`}
                </h1>
            </motion.div>
            
        </div>

    )
}

export default Home