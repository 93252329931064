import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Link } from "react-router-dom";
import { useEffect, useMemo} from 'react'
import Image from 'react-bootstrap/Image';

import '../css/carousel.css';
import { medusaClient } from "../utils/medusa_client";

// import required modules
import { FreeMode, Pagination } from 'swiper/modules';

export default function Carousel(props) {

    const mediaQuery = useMemo(() => window.matchMedia("(max-width: 550px)"), ["(max-width: 550px)"]);
    const [midMatch, setMidMatch] = useState(mediaQuery.matches);
    
    useEffect(() => {
        const onChange = () => setMidMatch(mediaQuery.matches);
        mediaQuery.addEventListener("change", onChange);
    
        return () => mediaQuery.removeEventListener("change", onChange);

    }, [mediaQuery]);


  return (

    <>
        {props.type == "products" ?
            <Swiper
            slidesPerView={1}
            spaceBetween={40}
            freeMode={true}
            centeredSlides={true}
            loop={false}
            pagination={false}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
            >
            {props.products && props.products.map((obj, idx) => {
                return (
                    <SwiperSlide>
                        <Link className="custom-nav-link" to={`/product/${obj.id}`} state={{ product: obj}}>
                            <section className="carousel">
                                <div className="product-card-img-container" >
                                    <img className="product_card-img" src={`${obj.images[0].url}`}/> 

                                </div>
                                <div className="flex product_card-text_container" >
                                    <h2>{`${obj.title}`}</h2>

                                    {/* <p className="product-card-desc">{`${props.product.desc}`}</p> */}

                                    <h4>{`$${obj.variants[0].prices[0].amount / 100}`}</h4>
                                </div>
                            

                            </section>            
                        </Link>
                    </SwiperSlide>

                )
            })}
        </Swiper>
        :
        <Swiper
            slidesPerView={midMatch ? 1 : 3}
            spaceBetween={100}
            freeMode={true}
            loop={false}
            pagination={false
            }
            modules={[FreeMode, Pagination]}
            className="mySwiper"
            >
            {props.products && props.products.map((obj, idx) => {
                return (
                    <SwiperSlide >
                        {/* <h1 className='secondary-color'>{obj.handle}</h1> */}
                        <Link style={{height: "100%"}} className="nav-link" to={`/shop/${obj.title}`} state={{type: "-1", collection: obj}} key={Math.random()}>
                            <h4 style={{position: "absolute", color: "#a78dd6", background: "#ff7c53",fontSize: "1.1rem", padding: "1.5%"}}>{obj.title}</h4>
                            <img src={`https://tfnzweaamqetvssepbzz.supabase.co/storage/v1/object/public/kumiworld/banners/${obj.handle}`} />
                        </Link>
                        
                    </SwiperSlide>

                )
            })}
        </Swiper>
    
        }
    </>
  )
}
