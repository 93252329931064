import React from 'react'
import { SearchBox, Hits  } from 'react-instantsearch';
import { UserContext } from "../context/userContext";
import {useContext} from 'react'
import "../css/search.css"
import { useState, useEffect} from 'react'
import { useParams } from "react-router-dom";

function Hit({ hit }) {
  // console.log("ghot", hit)
  return (
    <div>
      <p>{hit.title}</p>
      {/* <img src={hit.image} alt={hit.name} />
      <p>{hit.categories[0]}</p>
      <h1>{hit.name}</h1>
      <p>${hit.price}</p> */}
    </div>
  );
}
export default function Search() {
  const [displayHits, setDisplayHits] = useState(false)
  let { search_query } = useParams()
  // console.log("saerch,", search_query)
  const queryHook = (query, search) => {
    search(query);
  };

  useEffect(() => {
    return () => {
      console.log(1)
    }
  }, [])
  

  return (
    <div className='search-main-container'>
      <SearchBox value={'search_query'} searchAsYouType={false} queryHook={queryHook} onSubmit={() => {setDisplayHits(true)}}/>
      {displayHits && <Hits hitComponent={Hit} />}    
    </div>
  )
}
