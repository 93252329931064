import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { createClient } from '@supabase/supabase-js'
import { useCreateLineItem } from "medusa-react"
import { medusaClient } from "../utils/medusa_client";
//
import Medusa from "@medusajs/medusa-js"
import { CookiesProvider, useCookies } from "react-cookie";

export const UserContext = createContext();

const supabase = createClient('https://tfnzweaamqetvssepbzz.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRmbnp3ZWFhbXFldHZzc2VwYnp6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTM1MTA3MjMsImV4cCI6MjAwOTA4NjcyM30.0ctg8dHeLjGFl-_BXKzWPzZklwz85Vz4burx3eJ9Bm4')

const Context = ({ children }) => {
  const [medusa, setMedusa] = useState(medusaClient)
  const [session, setSession] = useState(null)
  const [cart, setCart] = useState();
  const [ cartId, setCartId]  = useState()
  const id = localStorage.getItem("cart_id")
  const [user, setUser] = useState()
  const [loggedIn, setLoggedIn] = useState(false)
  const [cookies, setCookie] = useCookies(["connect.sid"]);

  useEffect(() => {

    

    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
    })
    handleCreateCart()
    return () => subscription.unsubscribe()
  }, [])

    // const createLineItem = useCreateLineItem(cart.id)

    const handleAddItem = (values) => {
      // console.log("adding", values)
      medusa.carts.lineItems.create(cart.id, {
        variant_id: values.variant,
        quantity: parseInt(values.quantity),
      })
      .then(({ cart }) => setCart(cart))
      
    }
    

    const handleCreateCart = () => {
      // console.log("checking cart")
      if (id) {
        // console.log('user exists')
        
        medusa.carts.retrieve(id)
        .then(({ cart }) => setCart(cart))
      } else {
        medusa.carts.create(
          {region_id: "reg_01H96JJSAZ0GKRHZ9QBD39RAH7"}
        )
          .then(({ cart }) => {
            // console.log("cart", cart, user)
            localStorage.setItem("cart_id", cart.id)
            // assuming you have a state variable to store the cart
            setCart(cart)
          })
        }
    }

  const handleGuestEmail = (userEmail) => {
    if (cart) {
      return medusa.carts.update(cart.id, {
        email: userEmail
      }).then(({cart}) => setCart(cart))
    }
  }
      
  const handleAddAddress = (customAddress) => {
    // console.log("IN HANDLE ADDRESS STUFF", customAddress)
      if (cart) {

    
        return medusa.carts.update(cart.id, {
          shipping_address: {
            // company: customAddress.company,
            // first_name: customAddress,
            // last_name: customAddress,
            // address_1: customAddress,
            // address_2: "199 drive",
            // city: "denton",
            // country_code: "us",
            // province: "kentucky",
            // postal_code: '95616',
            // phone: "5304929269",
            company: customAddress.company,
            first_name: customAddress.first_name,
            last_name: customAddress.last_name,
            address_1: customAddress.address_1,
            address_2: customAddress.address_2,
            city: customAddress.city,
            country_code: customAddress.country_code,
            province: customAddress.province,
            postal_code: customAddress.postal_code,
            phone: customAddress.phone,
          },
        })
        .then(({ cart }) => {
          // console.log(cart.shipping_address)
          setCart(cart)
          return medusa.shippingOptions.listCartOptions(cart.id)
          .then(({ shipping_options }) => {
            // console.log('ssss',shipping_options.length)
            return shipping_options.length
          })
        })
    }

    
}
const handleAddShippingOption = () => {
  // console.log("IN HANDLEINGG ADDING SHJIPPING OPTION", cart.region_id)

  medusa.shippingOptions.listCartOptions(cart.id)
          .then(({ shipping_options }) => {
            // console.log('s',shipping_options.length)
            return shipping_options.length
          })

      
    }

  function logoutCustomer () {
    medusaClient.auth.deleteSession()
    .then(() => {
      // success
      // console.log("Logged Outs")
      setUser()
      setLoggedIn(false)
    })

}

useEffect(() => {

  // console.log("in user effect")
  let w =medusaClient.auth.getSession()
      .then(({ customer }) => {
      // console.log(customer.id);
      setLoggedIn(customer)
  }, () => {return "lol"});
  // console.log("wwww", w)

}, [user])

function associateCustomer (customer) {
  // console.log(customer)
    medusaClient.carts.update(cart.id, {
      customer_id: customer.id,
    })
    .then(({ cart, response }) => {
      setCart(cart)
      // console.log('res', response)
    })
}

// function getProductById (id) {
//   return medusaClient.products.retrieve(id)
//     .then(({ product }) => {
//       console.log(product.id);
//   });

// }

  return <UserContext.Provider value={{ session, setSession, supabase, cart, setCart, handleCreateCart, handleAddItem, handleAddAddress, handleGuestEmail, handleAddShippingOption, cartId, setCartId, user, setUser, logoutCustomer, associateCustomer, loggedIn}}>{children}</UserContext.Provider>;

};

export default Context;