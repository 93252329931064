import { useContext, useState } from "react";
import './App.css';
import './css/root.css'
import { Routes, Route } from 'react-router-dom';
import Home from './pages/home';
// import Foods from './components/pages/foods';
// import Schedule from './components/pages/schedule';
// import MyFridge from './components/pages/myFridge';

import MyNavbar from './components/navbar';
import { UserContext } from './context/userContext';
import Product from "./pages/product_page";
import Supabase_Client from "./components/supabase_client"
import Footer from "./components/footer";
import Nav from 'react-bootstrap/Nav';
import Admin from "./pages/admin";
import Shop from "./pages/shop";
import Start from "./pages/start";
import MyNavbar2 from "./components/navbar2";
import Art from "./pages/art";
import { ParallaxProvider } from 'react-scroll-parallax';

import { MedusaProvider, CartProvider } from "medusa-react"
import { QueryClient } from "@tanstack/react-query"

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Checkout from "./pages/checkout";
import MyLogin from "./pages/login";
import { CookiesProvider, useCookies } from "react-cookie";

// const queryClient = new QueryClient()
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch';
import Search from "./pages/search";
import { Configure } from 'react-instantsearch';
import About from "./pages/about";
import OrderConfirmation from "./pages/orderConfirmation";
const searchClient = algoliasearch('Q48P83F2H8', 'a0e7bc286ce7a8454a2a98ad8e84113c');
// {clientSecret && (
//   <Elements stripe={stripePromise} options={{
//     clientSecret,
//   }}>
//   <MyForm clientSecret={clientSecret} cartId={cartId} address={customAddress}/>
// </Elements>
function App() {
  const user = useContext(UserContext)
  return (
      <CookiesProvider>
        <InstantSearch searchClient={searchClient} indexName="products">
          {/* <Elements 
              stripe={stripePromise} options={{
              clientSecret,
            }}> */}
            <div>
              <div id="bg"></div>
              <MyNavbar />
              <ParallaxProvider >
                <Routes>
                  <Route 
                    path="/" 
                    element={<Start />}
                  />
                  <Route 
                    path="/product/:product_id" 
                    loader={({ params }) => {
                      console.log('app'); 
                    }} 
                    action={({ params }) => {console.log('actions'); return }}
                    element={<Product />} 
                  />
                  <Route 
                    path="/admin"
                    element={<Admin />}
                  />
                  <Route 
                    path="/shop/:type"
                    element={<Shop key={Math.random()}/>}
                    
                  />
                  <Route 
                    path="/start"
                    element={<Start />}
                  />
                  <Route 
                    path="/art"
                    element={<Art />}
                  />
                  <Route 
                    path="/checkout"
                    element={<Checkout />}
                  />
                  <Route 
                    path="/login"
                    element={<MyLogin />}
                  />
                  <Route 
                    path="/order"
                    element={<OrderConfirmation />}
                  />
                  <Route 
                    path="/about"
                    element={<About />}
                  />
                  <Route 
                    path="/search/:search_query"
                    loader={({ params }) => {
                      console.log(params.product_id); 
                    }} 
                    action={({ params }) => {}}
                    element={<Search />}
                  />
                </Routes>
                <Footer />
              </ParallaxProvider>
              
              
          </div>
          {/* </Elements> */}

        </InstantSearch >
      </CookiesProvider>
      // </MedusaProvider>
    // </Elements>
  );
}

export default App;
